<template>
  <background :start-positions="startPositions" class="background"/>
  <header-component class="header"/>
  <div class="article">
    <component
      v-for="(section, index) in article.sections"
      :is="getComponent(section.type)"
      :key="index"
      v-bind="section"
    />
  </div>
  <footer-component class="margin-footer"></footer-component>
</template>

<script>
import FooterComponent from '@/components/FooterComponent.vue';
import HeaderComponent from '@/components/HeaderComponent.vue';
import Background from '@/components/CircleBg.vue';
import articlesData from '@/assets/scripts/articlesLoader.js';
import TextSection from '../articlesComponents/TextSection.vue';
import ImageSection from '../articlesComponents/ImageSection.vue';
import TitleSection from '../articlesComponents/TitleSection.vue';
import ButtonSection from '../articlesComponents/ButtonSection.vue';
import SubtitleSection from '../articlesComponents/SubtitleSection.vue';
import BulletPointsSection from '../articlesComponents/BulletPointsSection.vue';
import ImageTextSection from '../articlesComponents/ImageTextSection.vue';
import youtubeSection from '../articlesComponents/YoutubeSection.vue';

export default {
  components: {
    TextSection,
    ImageSection,
    TitleSection,
    Background,
    HeaderComponent,
    ButtonSection,
    SubtitleSection,
    BulletPointsSection,
    ImageTextSection,
    youtubeSection,
    FooterComponent
  },
  data() {
    return {
      startPositions: [{ x: 50, y: 300 }, { x: 600, y: 80 }, { x: 900, y: 500 }],
      article: null
    };
  },
  created() {
    const articleName = this.$route.params.name;
    this.article = articlesData.find(article => article.name === articleName);
  },
  methods: {
    getComponent(type) {
      switch (type) {
        case 'text':
          return 'TextSection';
        case 'image':
          return 'ImageSection';
        case 'title':
          return 'TitleSection';
        case 'button':
          return 'ButtonSection';
        case 'subtitle':
          return 'SubtitleSection';
        case 'bullet-points':
          return 'BulletPointsSection';
        case 'image-text':
          return 'ImageTextSection';
        case 'youtube':
          return 'youtubeSection';
        default:
          return null;
      }
    }
  }
};
</script>

<style scoped>
.background {
  z-index: -100;
}

.article {
  padding: 16px;
}


  
</style>
