<template>
  <div class="container">
    <header-component class="header"/>
    <div class="cv-div">
      <iframe title="PDF" :src="`/pdfjs-4.2.67-dist/web/viewer.html?file=${documentUrl}`"></iframe>
    </div>
    <footer-component class="footer"/>
  </div>
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';
export default {
  name: "MyResume",
  data() {
    return {
      documentUrl: "/cv-dorian-rochette.pdf"
    }
  },
  components: {
    HeaderComponent,
    FooterComponent
  }
}
</script>

<style>
  .container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .cv-div {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  iframe {
    flex: 1;
    border: none;
  }
  
  .header, .footer {
    flex-shrink: 0;
  }
</style>
