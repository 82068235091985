<template>
    <div :class="['image-text-section', { 'image-right': imagePosition === 'right' }]">
      <div class="image-container">
        <img :src="image" :alt="imageAltText" />
      </div>
      <div class="text-container">
        <h2>{{ title }}</h2>
        <p>{{ content }}</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      title: {
        type: String,
        required: true
      },
      image: {
        type: String,
        required: true
      },
      imageAltText: {
        type: String,
        default: 'Image'
      },
      content: {
        type: String,
        required: true
      },
      imagePosition: {
        type: String,
        default: 'left',
        validator: function (value) {
          return ['left', 'right'].indexOf(value) !== -1;
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .image-text-section {
    display: flex;
    align-items: center;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 5%;
  }
  
  .image-text-section.image-right .image-container {
    order: 2;
  }
  
  .image-container {
    flex: 1;
    padding: 16px;
  }
  
  .image-container img {
    width: 100%;
    height: auto;
    border-radius: 20px;
    border: 1px solid black;
  }
  
  .text-container {
    flex: 2;
    padding: 16px;
  }
  
  .text-container h2 {
    font-size: 3em;
    color: black;
    margin-bottom: 16px;
  }
  
  .text-container p {
    color: black;
    font-size: 1.8em;
  }

  @media screen and (max-width: 1300px){
    .image-text-section {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 500px){
    .text-container h2 {
      font-size: 2em;
    }
    .image-text-section
    {
      margin-left: 0%;
      margin-right: 0%;
    }
    .text-container p {
      font-size: 1em;
    }
    .image-container{
      padding: 0px;
    }
    .text-container{
      padding: 0px;
      margin-bottom: 20px;
    }
    .text-container h2 {
      font-size: 1.5em;
    }
  }

  </style>
  