<template>
    <div class="bullet-points-section">
      <ul>
        <li v-for="(point, index) in points" :key="index">{{ point }}</li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      points: Array
    }
  };
  </script>
  
  <style scoped>
  .bullet-points-section {
    margin-right: 14%;
    margin-left: 14%;
  }
  .bullet-points-section ul {
    padding-left: 20px;
  }
  
  .bullet-points-section li {
    font-size: 2em;
    color: black;
    list-style: circle;
    list-style-type: disc;
    margin-bottom: 30px;
  }

  @media screen and (max-width: 500px){
    .bullet-points-section li {
      font-size: 1em;
    }
    .bullet-points-section
    {
      margin-left: 0%;
      margin-right: 0%;
    }
  }
  </style>
  