export default {
  title: 'Modernisation du site d\'un conservatoire',
  name: 'modernisation-site-conservatoire',
  image: require('@/assets/img/articleImg/modernisation-site-conservatoire/personat_romane.jpg'),
  sections: [
    { type: 'title', content: 'Modernisation du site d\'un conservatoire: Une refonte pour une expérience utilisateur améliorée' },
    { type: 'button', content: 'Me contacter', link: 'https://www.google.com' },
    { type: 'subtitle', content: 'Contexte' },
    { type: 'text', content: 'Dans le cadre d\'un projet de groupe, nous avons dû réaliser la refonte du site web d\'un conservatoire. Notre objectif était de moderniser et d\'améliorer l\'expérience utilisateur, en prenant en compte les attentes du client, les besoins spécifiques et les préférences des utilisateurs finaux.' },
    { type: 'subtitle', content: 'Analyse des besoins Clients / Utilisateurs' },
    { type: 'text', content: 'Nous avons commencé notre démarche en rédigeant un questionnaire client détaillé pour comprendre ses attentes et ses objectifs pour le nouveau site. Nous avons ensuite eu l\'opportunité d\'interviewer le client, ce qui nous a permis d\'approfondir notre compréhension de ses besoins et de clarifier les exigences du projet. Forts de ces informations, nous avons entrepris la création de plusieurs personas représentant les utilisateurs finaux potentiels du site. Ces personas nous ont aidés à déduire les attentes et les fonctionnalités clés que nous devions intégrer dans la refonte du site. Pour mieux appréhender le paysage concurrentiel, nous avons effectué une analyse approfondie de deux sites web similaires. Cette étape nous a permis d\'identifier les contenus, les fonctionnalités, l\'univers visuel et graphique incontournables qui devaient être pris en compte et adaptés pour notre projet.' },
    { type: 'image', image: require('@/assets/img/articleImg/modernisation-site-conservatoire/personat_guy.jpg'), altText: 'image du personnat de guy réalisé dans le cadre du projet' },
    { type: 'image', image: require('@/assets/img/articleImg/modernisation-site-conservatoire/personat_romane.jpg'), altText: 'image du personnat de romane lari réalisé dans le cadre du projet' },
    { type: 'subtitle', content: 'Analyse concurrentielle, organisation de l\'architecture et définition des livrables' },
    { 
      type: 'text', 
      content: `Pour mieux appréhender le paysage concurrentiel, nous avons effectué une analyse approfondie de deux sites web similaires. Cette étape nous a permis d'identifier les contenus, les fonctionnalités, l'univers visuel et graphique incontournables qui devaient être pris en compte et adaptés pour notre projet.
      Ensuite, nous avons utilisé la méthode de tri de carte pour organiser les idées et les fonctionnalités identifiées précédemment. Cela nous a permis d'établir une structure de base pour le site, définissant ainsi son squelette et sa navigation.
      Enfin, nous avons traduit tous les besoins, les attentes et les fonctionnalités en livrables concrets, tels que des maquettes interactives, des wireframes et des spécifications fonctionnelles détaillées, afin de guider le processus de développement et de conception du site.`
    },
    { type: 'image', image: require('@/assets/img/articleImg/modernisation-site-conservatoire/tri_de_carte.jpg'), altText: 'image du trie de carte réalisé pour la refonte du conservatoire' },
    { type: 'subtitle', content: 'Compétences mobilisés' },
    { 
      type: 'bullet-points', 
      points: [
        'Analyser les besoins clients et les attentes des utilisateurs',
        'Réaliser des entretiens avec le client pour recueillir des informations supplémentaires',
        'Créer des personas pour représenter les utilisateurs finaux',
        'Déduire les attentes des utilisateurs et les fonctionnalités à mettre en place',
        'Analyser la concurrence et les sites web similaires pour identifier les contenus, les fonctionnalités et l\'univers visuel à prendre en compte',
        'Effectuer un tri de cartes pour établir la structure du site (squelette)',
        'Traduire les besoins identifiés en livrables pour le projet de refonte du site'
      ]
    },
    { type: 'subtitle', content: 'Conclusion' },
    { 
      type: 'text', 
      content: 'Ce projet de refonte du site d\'un conservatoire a été une expérience enrichissante, tant sur le plan professionnel que personnel. En travaillant en équipe, nous avons pu combiner nos compétences et nos idées pour aboutir à une solution complète et optimale. Nous avons également acquis une meilleure compréhension des défis liés à la refonte d\'un site web, tels que l\'importance de la recherche préliminaire, l\'analyse concurrentielle et la création de personas pour orienter la conception. Nous avons également réalisé l\'importance de trouver un équilibre entre les attentes du client et les besoins des utilisateurs finaux. Ce projet de groupe nous a permis de développer nos compétences en matière de recherche, d\'analyse, de conception et de collaboration, tout en nous donnant une perspective concrète sur la réalisation d\'un projet de refonte de site web.'
    }
    
  ]
};
