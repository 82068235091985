export default {
  title: 'Analyse d\'un environnement économique et écologique',
  name: 'analyse-environement-ecnonomique-ecologique',
  image: require('@/assets/img/articleImg/analyse-environement-ecnonomique-ecologique/analyse-environement-cover.jpg'),
  sections: [
    { type: 'title', content: 'Anlyse de l\'environement économique et écologique en vue de l\'implantation d\'une entreprise' },
    { type: 'button', content: 'Me contacter', link: 'https://www.google.com' },
    { type: 'subtitle', content: 'Contexte' },
    { type: 'text', content: 'Nous avons incarné un groupe de jeunes entrepreneurs ambitieux désireux de lancer une agence de développement informatique écologique sur Le Puy-en-Velay. Notre objectif était de fournir des services de développement informatique de qualité tout en minimisant notre impact sur l\'environnement. Pour atteindre cet objectif, nous avons entrepris une analyse approfondie de l\'environnement économique et écologique de la région.' },
    { type: 'subtitle', content: 'Analyse de l\'environnement économique:' },
    { type: 'text', content: 'Au début de notre projet, nous avons effectué plusieurs actions pour mieux comprendre l\'environnement économique dans lequel notre agence de développement informatique allait évoluer. Nous avons réalisé une étude de la concurrence en identifiant deux entreprises locales offrant des services similaires aux nôtres. Nous avons également recherché des partenaires potentiels, tels que des entreprises de marketing digital et des agences de communication, ainsi que des entreprises offrant des services complémentaires tels que le développement web et la conception graphique.' },
    { type: 'image', image: require('@/assets/img/articleImg/analyse-environement-ecnonomique-ecologique/shema-des-differents-modéle-dhebergment.jpg'), altText: 'shema réprésentant les différent type d\'hebergement' },
    { type: 'subtitle', content: 'Mise en place de mesures écologiques:' },
    { 
      type: 'text', 
      content: `Conscients de l'impact environnemental de notre activité, nous avons mis en œuvre des mesures concrètes pour réduire notre empreinte écologique. Nous avons opté pour l'utilisation de technologies éco-responsables, telles que des serveurs à faible consommation d'énergie et des langages de programmation économes en ressources. Nous avons également optimisé la consommation énergétique en utilisant des systèmes qui ne nécessitent pas un fonctionnement en continu 24 heures sur 24. De plus, nous avons favorisé l'utilisation d'infrastructures mutualisées afin de réduire l'empreinte écologique par client. Nous avons sensibilisé notre personnel à l'importance de l'écologie et mis en place des pratiques éco-responsables au sein de notre entreprise. Enfin, nous avons décidé de soutenir des initiatives écologiques externes, telles que des projets de reforestation et de développement durable.`
    },
    { type: 'image', image: require('@/assets/img/articleImg/analyse-environement-ecnonomique-ecologique/shema-pue-mutualise.jpg'), altText: 'image du trie de carte réalisé pour la refonte du conservatoire' },
    { type: 'subtitle', content: 'Analyse concurrentielle, organisation de l \'architecture et définition des livrables' },
    { type: 'text', content: 'Pour mieux appréhender le paysage concurrentiel, nous avons effectué une analyse approfondie de deux sites web similaires. Cette étape nous a permis d\'identifier les contenus, les fonctionnalités, l\'univers visuel et graphique incontournables qui devaient être pris en compte et adaptés pour notre projet. Ensuite, nous avons utilisé la méthode de tri de carte pour organiser les idées et les fonctionnalités identifiées précédemment. Cela nous a permis d\'établir une structure de base pour le site, définissant ainsi son squelette et sa navigation. Enfin, nous avons traduit tous les besoins, les attentes et les fonctionnalités en livrables concrets, tels que des maquettes interactives, des wireframes et des spécifications fonctionnelles détaillées, afin de guider le processus de développement et de conception du site.' },
    { type: 'subtitle', content: 'Compétences mobilisés' },
    { 
      type: 'bullet-points', 
      points: [
        'Conduire un projet',
        'Collaborer au sein d\'une équipe informatique',
      ]
    },
    { type: 'subtitle', content: 'Conclusion' },
    { 
      type: 'text', 
      content: 'Ce projet nous a permis de développer et de mobiliser un large éventail de compétences, allant de la recherche et de l\'analyse à la communication, en passant par la gestion des ressources humaines et la responsabilité sociale. Nous avons acquis une compréhension approfondie de l\'importance de l\'écologie dans le domaine du développement informatique et nous sommes convaincus que notre agence sera en mesure de fournir des services de qualité tout en minimisant notre impact sur l\'environnement. Cette expérience nous a préparés pour notre future carrière professionnelle en tant qu\'entrepreneurs responsables et soucieux de l\'environnement.'
    },
    
  ]
};
