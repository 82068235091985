import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import ArticleView from '../views/ArticleView.vue';
import ResponsibleView from '../views/ResponsibleView.vue';
import CvView from '../views/CvView.vue';
import articlesData from '@/assets/scripts/articlesLoader.js';

const routes = [
  {
    path: '',
    name: 'Home',
    component: HomeView,
    meta: {
      title: 'Portfolio Dorian Rochette',
    },
  },
  {
    path: '/article/:name',
    name: 'Article',
    component: ArticleView,
    beforeEnter: (to, from, next) => {
      const articleName = to.params.name;
      const article = articlesData.find(article => article.name === articleName);
      if (article) {
        document.title = `Portfolio Dorian Rochette - ${article.title}`;
      } else {
        document.title = 'Portfolio Dorian Rochette - Article';
      }
      next();
    },
  },
  {
    path: '/numerique-responsable',
    name: 'NumeriqueResponsable',
    component: ResponsibleView,
    meta: {
      title: 'Portfolio Dorian Rochette - Numérique Responsable',
    },
  },
  {
    path: '/cv',
    name: 'Cv',
    component: CvView,
    meta: {
      title: 'Portfolio Dorian Rochette - CV',
    },
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to) => {
  if (!to.meta?.title) {
    document.title = 'Portfolio Dorian Rochette';
  }
});

export default router;
