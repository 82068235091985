export default {
    title: 'Hide & Street une application de cache-cache urbain',
    name: 'hide-and-street',
    image: require('@/assets/img/articleImg/hide-and-street/hs-cover.jpg'),
    sections: [
      { type: 'title', content: 'Création d\'une application de cache cache unbain un projet pas comme les autres' },
      { type: 'button', content: 'Me contacter', link: 'https://www.google.com' },
      { type: 'subtitle', content: 'Contexte' },
      { type: 'text', content: 'Hide & Street est un projet universitaire réalisé dans le cadre de ma formation. Les consignes pour ce projet étaient simples : créer un jeu sans contrainte technologique ou créative. Nous avons donc repris une idée que nous avions en tête depuis un moment : créer une application permettant de jouer à cache-cache dans les rues d’une ville, grâce à des aides telles que la localisation. Cette application devait également intégrer des concepts du numérique responsable, c\'est pourquoi nous avons décidé de la rendre accessible aux personnes atteintes de déficience visuelle et de handicap cognitif.' },
      { type: 'subtitle', content: 'En combien de temps et avec quelles technologies' },
      { type: 'text', content: 'La conception de l’application s’est déroulée en 3 mois, débutant le 8 janvier 2024. La première soutenance a eu lieu le 26 janvier 2024, où le projet devait être complété à 95 %. La seconde soutenance finale s\'est tenue le 29 mars 2024. \n\nQuand aux technologies pour le frontend, nous avons utilisé Flutter, un framework open source très populaire, offrant une grande quantité de documentation et de support en ligne. Pour le backend, nous avons choisi Node.js, connu pour sa rapidité, et PostgreSQL, une base de données open source performante et fiable.' },
      { type: 'image', image: require('@/assets/img/articleImg/hide-and-street/rendu-final-application.png'), altText: 'image du trie de carte réalisé pour la refonte du conservatoire' },
      { type: 'subtitle', content: 'Notre aproche du numérique responsable dans ce projet' },
      { 
        type: 'text', 
        content: `Diverses mesures ont été mises en œuvre pour minimiser l'impact environnemental de Hide & Street. Par exemple, nous avons réduit le nombre de requêtes envoyées aux serveurs de 90 % par partie, diminuant ainsi la consommation de bande passante. En matière d'accessibilité, nous avons intégré des assistances pour que tout le monde puisse jouer. Le mode "Déficience Visuelle" remplace les éléments d'interface peu utilisables pour les malvoyants et simplifie l'UI pour les personnes ayant des handicaps cognitifs. De plus, Hide & Street est compatible avec Talkback, un utilitaire Android qui décrit à haute voix le contenu affiché à l'écran.`
      },
      { type: 'youtube', videoId: 'FWKmdzNGBLY' },
      { type: 'subtitle', content: 'Compétences mobilisés' },
      { 
        type: 'bullet-points', 
        points: [
          'Identifier les technologies appropriées pour un projet spécifique',
          'Déterminer les contraintes et les objectifs du projet',
          'Conduire un projet',
          'Collaborer au sein d\'une équipe informatique'
        ]
      },
      { type: 'subtitle', content: 'Conclusion' },
      { 
        type: 'text', 
        content: 'Ce projet m\'a permis de développer des compétences techniques précieuses et d\'acquérir une expérience concrète en gestion de projet. Travailler sur Hide & Street m\'a appris l\'importance de la collaboration au sein d\'une équipe, l\'adaptation aux imprévus et la gestion efficace du temps pour respecter des délais serrés. De plus, la création d\'une application ludique et innovante m\'a montré comment combiner créativité et technologie pour répondre aux besoins des utilisateurs. Cette aventure m\'a également sensibilisé aux défis liés à l\'accessibilité et m\'a motivé à toujours chercher des solutions inclusives dans mes futurs projets.'
      }
      
    ]
  };
  