<template>
    <div class="background">
      <div
        v-for="(circle, index) in circles"
        :key="index"
        class="circle"
        :style="{ 
          width: circle.size + 'px', 
          height: circle.size + 'px', 
          background: circle.color, 
          transform: 'translate(' + circle.x + 'px, ' + circle.y + 'px)',
          transition: 'transform 1.5s linear' // Transition plus lente
        }"
      ></div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      startPositions: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        circles: [],
        numCircles: this.startPositions.length, // Nombre de cercles
        maxWidth: window.innerWidth, // Largeur maximale de la fenêtre
        maxHeight: window.innerHeight, // Hauteur maximale de la fenêtre
        radius: 800, // Rayon de déplacement
      };
    },
    mounted() {
      // Créer les cercles initiaux
      for (let i = 0; i < this.numCircles; i++) {
        const startPosition = this.startPositions[i] || { x: this.maxWidth / 2, y: this.maxHeight / 2 };
        this.createCircle(startPosition.x, startPosition.y);
      }
      // Déplacer les cercles à intervalles réguliers
      this.moveCircles();
    },
    methods: {
      createCircle(startX, startY) {
        const size = 300 + (Math.random()* 100); // Taille fixe de 200 pixels
        const color = '#232B75'; // Couleur spécifiée
        const angle = Math.random() * Math.PI * 2; // Angle aléatoire initial
        this.circles.push({ size, color, x: startX, y: startY, angle });
      },
      moveCircles() {
        setInterval(() => {
          this.circles.forEach(circle => {
            // Générer un nouvel angle aléatoire
            const angleChange = Math.random() * Math.PI / 2 - Math.PI / 4; // Variation d'angle entre -PI/4 et PI/4
            circle.angle += angleChange;
            // Calculer les nouvelles positions x et y en fonction de l'angle
            const newX = circle.x + Math.cos(circle.angle) * 10; // La valeur 10 contrôle la vitesse
            const newY = circle.y + Math.sin(circle.angle) * 10;
            // Vérifier si les nouvelles positions sortent de l'écran, et ajuster si nécessaire
            if (newX < 0 || newX > this.maxWidth || newY < 0 || newY > this.maxHeight) {
              circle.angle += Math.PI; // Changer de direction si le cercle sort de l'écran
            } else {
              circle.x = newX;
              circle.y = newY;
            }
          });
        }, 100); // Intervalles de déplacement ajustés pour être plus lents
      },
    },
  };
  </script>
  
  <style scoped>
  .background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .circle {
    position: absolute;
    border-radius: 50%;
    opacity: 1; /* Opacité des cercles */
    filter: blur(170px); /*Appliquer l'effet de flou*/
  }
  </style>
  