<template>
  <div class="text-section">
    <h2 class="article-title">{{ content }}</h2>
  </div>
</template>

<script>
export default {
  props: {
    content: String
  }
};
</script>

<style scoped>
.text-section {
  margin-top: 12%;
  margin-right: 14%;
  margin-left: 14%;
  margin-bottom: 5%;
}

.article-title {
  font-size: 5em;
  text-align: center;
}

@media screen and (max-width: 500px){
    
    .article-title {
      font-size: 2em;
    }
    .text-section
    {
      margin-left: 0%;
      margin-right: 0%;
    }
}
</style>
