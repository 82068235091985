<template>
    <div class="text-section">
      <p class="article-text" >{{ content }}</p>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      content: String
    }
  };
  </script>
  
  <style scoped>
  .text-section {
    margin-right: 14%;
    margin-left: 14%;
  }

  .article-text {
    font-size: 2em;
    color: black;
    text-align: left;
  }

  @media screen and (max-width: 500px){
    .article-text {
      font-size: 1em;
    }

    .text-container h2 {
      font-size: 2em;
    }
    .text-section
    {
      margin-left: 0%;
      margin-right: 0%;
    }
  }

  
  </style>
  