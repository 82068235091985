<template>
  <header class="header-container" :class="{ 'menu-open': isMenuOpen }">
    <background class="bg-animated" :start-positions="startPositions" v-if="isMenuOpen"/>
    
    <div class="header-content">
      <router-link to="/" class="En-Menu"><img src="../assets/img/logo.svg" height="60px" alt="logo portfolio dorian rochette"></router-link>
      <div class="En-Menu" @click="toggleMenu">
        <img v-if="!isMenuOpen" src="../assets/img/navMenu.svg" height="60px" alt="ouvrir le menu de navigation">
        <img v-else src="../assets/img/NavBarClose.svg" height="60px" alt="fermer le menu de navigation">
      </div>
    </div>
    <div v-if="isMenuOpen" class="menu-overlay" @click="closeMenu"></div>
  </header>
  <div v-if="isMenuOpen" class="white-bg">
    <div class="menu-background">
      <ul class="menu-item">
        <li 
          :class="{ 'hovered': isHovered('home') }"
          @mouseover="setHovered('home')"
          @mouseleave="setHovered(null)"
          @click="navigateAndCloseMenu('/')"
        >
          <a href="#" class="menu-item">ACC<span class="wide-letter">U</span><span class="wide-letter">EI</span>L</a>
        </li>
        <li 
          :class="{ 'hovered': isHovered('responsable') }"
          @mouseover="setHovered('responsable')"
          @mouseleave="setHovered(null)"
          @click="navigateAndCloseMenu('/numerique-responsable')"
        >
          <a href="#" class="menu-item">LE N<span class="wide-letter">UM</span>ERIQUE RESP<span class="wide-letter">O</span>NSABLE</a>
        </li>
        <li 
          :class="{ 'hovered': isHovered('cv') }"
          @mouseover="setHovered('cv')"
          @mouseleave="setHovered(null)"
          @click="navigateAndCloseMenu('/cv')"
        >
          <a href="#" class="menu-item">MON <span class="wide-letter">CV</span></a>
        </li>
        <li 
          :class="{ 'hovered': isHovered('contact') }"
          @mouseover="setHovered('contact')"
          @mouseleave="setHovered(null)"
          @click="sendEmail()"
        >
          <a href="#" class="menu-item">M<span class="wide-letter">E</span> CONTAC<span class="wide-letter">TER</span></a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Background from './CircleBg.vue';

export default {
  components: {
    Background,
  },
  data() {
    return {
      hoveredSection: null,
      startPositions: [{ x: 50, y: 300 }, { x: 600, y: 80 }, { x: 900, y: 500 }],
      isMenuOpen: false
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      if (this.isMenuOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    },
    sendEmail() {
      const mailtoLink = 'mailto:dorianrochette@gmail.com?subject=Contact%20Request&body=Bonjour,%0A%0AVeuillez%20entrer%20votre%20message%20ici.';
      window.location.href = mailtoLink;
    },
    closeMenu() {
      this.isMenuOpen = false;
      document.body.style.overflow = 'auto';
    },
    navigateAndCloseMenu(path) {
      this.$router.push(path);
      this.closeMenu();
    },
    setHovered(section) {
      this.hoveredSection = section;
    },
    isHovered(section) {
      if(this.hoveredSection != null)
      {
        // Code logique supplémentaire si nécessaire
      }
      return this.hoveredSection === section;
    }
  }
};
</script>

<style scoped>

div.white-bg {
  height: 100vh;
  background-color: #fff;
}

.hovered::before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #181D3E;
  border-radius: 50%;
  margin-right: 30px;
  animation: pulse 2s infinite alternate;
  vertical-align: middle;
}

ul.menu-item {
  z-index: 2; /* Assurez-vous que les éléments de la liste sont au-dessus de l'arrière-plan */
  margin-left: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

li {
  display: flex;
  align-items: center;
  z-index: 3; /* Assurez-vous que les éléments de la liste sont au-dessus de l'arrière-plan */
}

a.menu-item {
  font-size: 5rem;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(2.5);
  }
}

/* Le reste de votre style reste inchangé */

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
  z-index: 10;
  height: 10vh;
}

.En-Menu {
  display: flex;
  z-index: 10;
  gap: 30px;
  align-items: center;
}

p {
  font-size: 2rem;
}

.menu-overlay {
  position: fixed;
  top: 10vh;
  left: 0;
  width: 100%;
  height: 90vh;
  background-color: rgba(255, 255, 255, 0.8);
}

.bg-animated {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Assurez-vous que l'arrière-plan est en dessous du contenu */
}

.menu-background {
  position: fixed;
  top: 10vh;
  left: 0;
  width: 100%;
  height: 90vh;
  z-index: 10;
  background-color: transparent;
}

.menu-open .header-content {
  background-color: #fff;
}

@media screen and (max-width: 500px){
    a.menu-item {
      font-size: 2.7em;
    }
}
    
  
</style>
