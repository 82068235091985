export default {
  title: 'Exploration des technologies de développement web',
  name: 'exploration-technologies-dev-web',
  image: require('@/assets/img/articleImg/exploration-technologies-dev-web/tech-web-cover.jpg'),
  sections: [
    { type: 'title', content: 'Exploration des meilleures technologies pour un environnement de développement complet' },
    { type: 'button', content: 'Me contacter', link: 'https://www.google.com' },
    { type: 'subtitle', content: 'Contexte' },
    { type: 'text', content: 'Dans le cadre d\'un projet de groupe, nous dû choisir les technologies adaptées pour la création d\'un outil web de gestion de game jams. Notre objectif était de développer une plateforme permettant de référencer les participants, les groupes, le classement et les notes finales, ainsi que les jeux, afin de constituer des archives pour les éditions futures et faciliter les rétrospectives.' },
    { type: 'subtitle', content: 'Etapes du projet' },
    { type: 'image-text', title: 'Le choix d’un serveur web', image: require('@/assets/img/articleImg/exploration-technologies-dev-web/tech-performance.jpg'), imageAltText: 'comparaison des performance des différents serveurs web', content: 'La première étape de notre projet consistait à sélectionner une technologie d\'hébergement web parmi les options disponibles, telles que Caddy, Apache et Nginx. Chaque technologie offrait ses propres avantages et inconvénients en termes de performances, de facilité de configuration et de support de fonctionnalités spécifiques. Nous avons effectué des recherches approfondies, examiné des études de cas pour évaluer les différentes options et pour plus de facilité d’installation nous avons opté pour Apache.', imagePosition: 'right' },
    { type: 'image-text', title: 'Le choix d’une base de donnée', image: require('@/assets/img/articleImg/exploration-technologies-dev-web/db-performence-chart.jpg'), imageAltText: 'comparaison des performance des bases de données', content: 'Ensuite, nous nous sommes penchés sur le choix d\'une technologie de base de données adaptée à notre application. Parmi les possibilités qui s\'offraient à nous, nous avons évalué des options telles que MongoDB, PostgreSQL et MariaDB. Chacune de ces bases de données avait ses propres caractéristiques en termes de performance, de scalabilité et de flexibilité. Nous avons pris en compte les besoins spécifiques de notre application mais pour plus de facilité d’utilisation nous avons choisi Maria DB.', imagePosition: 'left' },
    { type: 'image-text', title: 'Le choix d’une base de donnée', image: require('@/assets/img/articleImg/exploration-technologies-dev-web/phpstorm-illustration.jpg'), imageAltText: 'image d\'illustration du logiciel phpstorm', content: 'Enfin, nous avons abordé le choix d\'un environnement de développement intégré (IDE) pour notre projet. Parmi les possibilités qui se présentaient à nous, nous avons évalué des outils populaires tels que PhpStorm, Visual Studio Code et Atom. Nous avons pris en compte des critères tels que la prise en charge des langages de programmation utilisés dans notre projet, les fonctionnalités d\'édition et de débogage, ainsi que l\'expérience utilisateur globale. Notre objectif était de choisir un IDE qui faciliterait le développement, l\'intégration et le débogage de notre application web. Nous avons finalement choisi phpstorm pour ses fonctionnalités plus poussés que les autres IDE.', imagePosition: 'right' },
    { type: 'subtitle', content: 'Compétences mobilisés' },
    { 
      type: 'bullet-points', 
      points: [
        'Identifier les technologies appropriées pour un projet spécifique',
        'Analyser les avantages et les inconvénients des différentes technologies',
        'Évaluer la faisabilité technique d\'une technologie pour un projet donné',
        'Comprendre les exigences fonctionnelles et non fonctionnelles du projet',
        'Déterminer les contraintes et les objectifs du projet',
        'Évaluer les ressources nécessaires pour la mise en œuvre du projet',
        'Suivre les tendances et les avancées technologiques dans le domaine concerné',
        'Rechercher et évaluer les nouvelles technologies pertinentes pour le projet',
      ]
    },
    { type: 'subtitle', content: 'Conclusion' },
    { 
      type: 'text', 
      content: 'Ce projet de groupe a été une expérience enrichissante qui m\'a permis d\'acquérir une meilleure compréhension des enjeux liés au choix des technologies dans le développement d\'une application web. J\'ai appris à évaluer les différentes options en fonction de critères spécifiques et à rechercher des informations pertinentes. En travaillant en équipe, nous avons également développé nos compétences en communication et en collaboration. J\'ai réalisé l\'importance de choisir les bonnes technologies pour répondre aux besoins spécifiques de notre projet. Chaque décision que nous avons prise avait des conséquences directes sur les performances, la scalabilité et la facilité de maintenance de notre application. En évaluant attentivement les avantages et les inconvénients de chaque technologie, nous avons pu sélectionner celles qui répondaient le mieux à nos besoins. En conclusion, le choix des technologies pour un projet web est une étape cruciale qui peut avoir un impact significatif sur la réussite de celui-ci. Notre projet de groupe m\'a donné l\'occasion de développer mes compétences en recherche, en analyse et en prise de décision. Ce projet m\'a permis de mieux comprendre l\'importance de choisir les bons outils et technologies.'
    }
    
  ]
};
