<template>
    <transition name="tooltip-fade">
      <div v-if="visible" class="tooltip" :style="{ top: `${position.y}px`, left: `${position.x}px` }">
        {{ text }}
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    data() {
      return {
        visible: false,
        text: '',
        position: { x: 0, y: 0 }
      };
    },
    methods: {
      showTooltip(text, event) {
        this.text = text;
        this.position = { x: event.clientX + 10, y: event.clientY + 10 };
        this.visible = true;
      },
      hideTooltip() {
        this.visible = false;
      },
      updatePosition(event) {
        const tooltipWidth = this.$el.offsetWidth;
        const tooltipHeight = this.$el.offsetHeight;
        const x = event.clientX + 10;
        const y = event.clientY + 10;
        
        // Adjust tooltip position if it touches the right or bottom edges
        const maxX = window.innerWidth - tooltipWidth - 10;
        const maxY = window.innerHeight - tooltipHeight - 10;
        this.position.x = Math.min(x, maxX);
        this.position.y = Math.min(y, maxY);
      }
    }
  };
  </script>
  
  <style scoped>
.tooltip {
  position: fixed;
  background-color: white;
  padding: 5px 10px;
  margin: 10px;
  border-radius: 5px;
  font-size: 30px;
  pointer-events: none;
  z-index: 10000;
  margin: 10px;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowed text */
  text-overflow: ellipsis; /* Display ellipsis for overflowed text */
}
  
  .tooltip-fade-enter-active,
  .tooltip-fade-leave-active {
    transition: opacity 0.3s;
  }
  
  .tooltip-fade-enter,
  .tooltip-fade-leave-to {
    opacity: 0;
  }
  </style>
  