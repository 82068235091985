<template>
    <svg width="66" height="106" viewBox="0 0 66 106" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3" y="3" width="60" height="100" rx="30" stroke="#181D3E" stroke-width="6"/>
      <circle class="animate-circle" cx="32.5" cy="31.5" r="13.5" stroke="#181D3E" stroke-width="6"/>
    </svg>
</template>
  
  <script>
  export default {
    name: 'AnimatedCircle',
  };
  </script>
  
  <style>
  @keyframes moveCircle {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(45px); /* Adjust this value as needed */
    }
  }

  
  .animate-circle {
    animation: moveCircle 2s ease-in-out infinite;
  }
  </style>
  