<template>
  <div>
    <header-component class="header"/>
    <div id="home">
      <div class="content">
        <h1>
          <span class="bordered">SALUT, JE SUIS</span> D<span class="wide-letter-2">ORIA</span>N RO<span class="wide-letter-2">C</span>H<span class="wide-letter-2">E</span>TT<span class="wide-letter-2">E</span><br>
          <span class="bordered">UN DEVELOPPEUR</span> <span class="wide-letter-2">WE</span>B FULLSTA<span class="wide-letter">C</span>K
        </h1>
        <div class="buttons">
          <a href="#" class="text-link" @click.prevent="redirectToRandomProject">→ laissez-vous surprendre</a>
          <a href="#" class="text-link" @click.prevent="scrollToProjects">→ voir mes projets</a>
        </div>
        <div class="slidediv">
          <slide-component class="slideCompo"/>
        </div>
      </div>
    </div>
    <project-list-component class="project-list" :projects="articlesData"/>
    <footer-component class="margin-footer"></footer-component>
    <background :start-positions="startPositions"/>
  </div>
</template>

<script>
import Background from '@/components/CircleBg.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import HeaderComponent from '@/components/HeaderComponent.vue';
import SlideComponent from '@/components/SlideComponent.vue';
import ProjectListComponent from '@/components/MyProjectsComponents.vue';
import articlesData from '@/assets/scripts/articlesLoader.js';

export default {
  name: 'HomeView',
  components: {
    HeaderComponent,
    Background,
    SlideComponent,
    ProjectListComponent,
    FooterComponent
  },
  data() {
    return {
      startPositions: [{ x: 50, y: 300 }, { x: 600, y: 80 }, { x: 900, y: 500 }],
      articlesData
    };
  },
  methods: {
    scrollToProjects() {
      const projectListElement = this.$el.querySelector('.project-list');
      if (projectListElement) {
        projectListElement.scrollIntoView({ behavior: 'smooth' });
      }
    },
    redirectToRandomProject() {
      const randomProjectIndex = Math.floor(Math.random() * this.articlesData.length);
      const randomProject = this.articlesData[randomProjectIndex];
      this.$router.push({ name: 'Article', params: { name: randomProject.name } });
    }
  }
};
</script>

<style>
@import url(../assets/css/Main.css);
@media screen and (max-width: 500px){
  h1{
    font-size: 3em;
  }
  div.buttons{
    flex-direction: column;
    gap: 20px;
  }
  a.text-link{
    margin-top: 30px;
  }
}

.content {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 96vh;
  z-index: 1;
}

.header {
  background: none;
}

.buttons {
  margin-top: 10px;
  display: flex;
  font-size: 1.5em;
  justify-content: center;
  gap: 80px;
}

.slideCompo {
  height: 50px;
  margin: 0;
}

.slidediv {
  height: 35vh;
  display: flex;
  justify-content: center;
  align-items: end;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -20;
}
</style>
